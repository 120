



























































































































import CuentasBancarias from "@/components/web/Pago/CuentasBancarias.vue";
import PedidoService from "@/api/pedido/PedidoService";
import PagosServices from "@/api/PagosServices";
import CartStore from "@/store/modules/CartStore";
import PaymentezDebit from "@/components/shared/PaymentezDebit.vue";
import AuthStore from "@/store/modules/AuthStore";
import PagoPlux from "@/components/shared/payments/PagoPlux.vue";

import Vue from "vue";
export default Vue.extend({
  components: {
    CuentasBancarias,
    PaymentezDebit,
    PagoPlux
  },
  data() {
    return {
      imagen: null,
      pedido: { id: 0, displayTotal: "" },
      plataformaPago: "",
      loading: false,
      paymentezIsActive: process.env.VUE_APP_PAYMENTEZ_ACTIVE === "true"
    };
  },
  created() {
    this.loadPedido();
    this.loadPlataformaPagoFromPedido();
    console.log("ESTE ES EL VALOR DE LA PLATAFORMA DE PAGO ", this.plataformaPago);
    CartStore.cleanCart();
  },
  methods: {
    enviar() {
      const formData = new FormData();
      formData.append("image", this.imagen || "");

      PedidoService.sendPapeleta("" + this.pedido.id, formData)
        .then(() => {
          //
          this.$swal({
            icon: "success",
            title: "¡Bien!",
            text:
              "Su documento se adjuntó correctamente. La confirmación de su depósito o transferencia puede tomar de 24 a 72 Horas laborables."
          }).then(() => {
            this.$router.push("/");
          });
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "¡Lo sentimos!",
            text: "Tu documento no se adjuntó. Por favor, vuelve a intentar"
          });
        });
    },
    loadPedido() {
      let id = "0";
      if (this.$route.query && this.$route.query.idPedido) {
        id = `${this.$route.query.idPedido}`;
      }

      PedidoService.getPedido(id)
        .then(response => {
          if (response) {
            this.pedido = response;
          }
        })
        .catch(() => {
          console.log("no se pudo obtener el pedido");
        });
    },
    loadPlataformaPagoFromPedido() {
      let id = "0";

      if (this.$route.query && this.$route.query.idPedido) {
        id = `${this.$route.query.idPedido}`;
      }
      const parsedInt = parseInt(id);
      PagosServices.getPlataformaPagoFromPedido(isNaN(parsedInt) ? 0 : parsedInt)
        .then(response => {
          console.log(response);
          if (response) {
            this.plataformaPago = response.abreviatura;
            console.log("ESTE ES EL VALOR DE LA PLATAFORMA DE PAGO ", this.plataformaPago);
          }
        })
        .catch(() => {
          console.log("no se pudo obtener la plataforma de pago");
          this.plataformaPago = "";
        });
    },
    //paymentez
    onPaymentezResponse(response: any) {
      console.log(response.transaction);

      switch (response.transaction.status) {
        case "pending":
          /*
          this.loading = true;
          PedidoService.pagoWebTarjeta(this.pedido.id, response)
            .then(() => {
              // start notify
              this.$swal({
                icon: "warning",
                title: "¡Pago en Revisión por el Banco!",
                text:
                  "En caso de no recibir ningun cargo en su tarjeta, comuniquese con el banco antes de reintentar. Le notificaremos a su correo si es aceptado."
              }).then(() => {
                this.$router.push("/");
              });
              // end notify
            })
            .finally(() => {
              this.loading = false;
            });
          */
          this.$swal({
            icon: "warning",
            title: "¡Pago en Revisión por el Banco!",
            text:
              "En caso de no recibir ningun cargo en su tarjeta, comuniquese con el banco antes de reintentar. Le notificaremos a su correo si es aceptado."
          }).then(() => {
            this.$router.push("/");
          });

          break;
        case "failure":
          this.$swal({
            icon: "error",
            title: "¡Error!",
            text: "Su pago fue declinado por el banco."
          }).then(() => {
            //this.$router.push("/");
          });
          break;
        case "success":
          /*
          this.loading = true;
          PedidoService.pagoWebTarjeta(this.pedido.id, response)
            .then(() => {
              // start notify : success
              this.$swal({
                icon: "success",
                title: "¡Bien!",
                text:
                  "Su pago ha sido aceptado de forma exitosa, por favor espere el correo de confirmación."
              }).then(() => {
                this.$router.push("/");
              });
              // end notify
            })
            .finally(() => {
              this.loading = false;
            });
            */
          this.$swal({
            icon: "success",
            title: "¡Bien!",
            text:
              "Su pago ha sido aceptado de forma exitosa, por favor espere el correo de confirmación."
          }).then(() => {
            this.$router.push("/");
          });
          break;
      }
    }
  },
  computed: {
    reciboSeleccionado: {
      get(): boolean {
        return !this.imagen ? true : false;
      }
    },
    userID: {
      get(): number {
        return AuthStore.getPersonaID;
      }
    },
    userFullName: {
      get(): string {
        return AuthStore.getPersonaNombre;
      }
    },
    username: {
      get(): string {
        return AuthStore.getUsername;
      }
    },
    orderDescription: {
      get(): string {
        if (this.pedido && this.pedido.id) {
          return `Pedido #${this.pedido.id}`;
        }
        return "Sin descripción";
      }
    },
    orderReference: {
      get(): string {
        if (this.pedido && this.pedido.id) {
          return `${this.pedido.id}`;
        }
        return "";
      }
    },
    telefono: {
      get(): string {
        return AuthStore.getPersonaTelefono;
      }
    },
    identficacion: {
      get(): string {
        return AuthStore.getPersonaIdentificacion;
      }
    },
    isManager(): boolean {
      return AuthStore.getLoggedIn && AuthStore.getRole !== "0";
    }
  }
});
