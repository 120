import httpClient from "./http-client";

const BASE_ENDPOINT = "/pagos";

class PagosService {
    
    /**
     * 
     * @returns 
     */
    getPlataformaPagos() {
      return httpClient.get(`${BASE_ENDPOINT}/plataformas`).then(response => {
        return response.data;
      });
    }

    /**
     * 
     * @param idPedido 
     * @returns 
     */
    getPlataformaPagoFromPedido(idPedido: number) {
      return httpClient
      .get(`${BASE_ENDPOINT}/plataformas/pedidos/${idPedido}`)
      .then(response => {
        if (response.data && response.data.success) {
          return response.data.data;
        }

        return null
      }).catch(error => {
        return null
      });
    }
  }
  
  export default new PagosService();
  