






import Vue from "vue";

export default Vue.extend({
    props: {
        userID: {
            type: Number,
            default: 0
        },
        description: String,
        reference: String,
        amount: Number,
        username: String,
        userFullName: String,
        telefono: String,
        identificacion: String,
        vat: {
            type: Number,
            default: 0
        },
        taxPercentage: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            pagopluxEnvMode: process.env.VUE_APP_PAGOPLUX_ENV_MODE == 'prod' ? process.env.VUE_APP_PAGOPLUX_ENV_MODE :'sandbox',
            pagopluxOwnerEmail: process.env.VUE_APP_PAGOPLUX_OWNER_EMAIL ?? "patricio@keeprunning.ec",
            pagopluxButtonId: "PagoPluxBtn",
            serverUrl: process.env.VUE_APP_API ?? "http://localhost:4000"
        };
    },
    methods: {
        buildPagoPluxPayload(
            amount: number, 
            customerEmail: string, 
            name: string, 
            address: string,
            phone: string,
            idElement: string | null, 
            extras: string
        ) {
            
            return {
                PayboxRemail: process.env.VUE_APP_PAGOPLUX_EMAIL ?? "patricio@keeprunning.ec",
                PayboxSendmail: customerEmail,
                PayboxRename: "Keep Running",
                PayboxSendname: name,
                PayboxBase0: String(amount),
                PayboxBase12: "0",
                PayboxDescription: "Pago de Carrera",
                PayboxLanguage: "es",
                PayboxDirection: address,
                PayBoxClientPhone: phone,
                PayBoxClientIdentification: this.identificacion,
                PayboxProduction: process.env.VUE_APP_STAGE == 'prod',                
                PayboxEnvironment: process.env.VUE_APP_STAGE == 'prod' ? 'prod' : 'sandbox',
                PayboxPagoPlux: idElement ? true : false,
                PayboxIdElement: idElement,
                PayboxExtras: extras
            }
        },
        initializeJQueryScript() {
            const script = document.createElement("script");
            script.type = "text/javascript";
            
            script.src = "https://code.jquery.com/jquery-3.4.1.min.js";

            //script.onload = this.onJQueryLoaded;

            document.head.appendChild(script);
        },
        onJQueryLoaded() {
            this.waitForAllRenderedCorrectly();
        },
        waitForAllRenderedCorrectly() {
            //setTimeout(() => {
                this.initializaPagoPluxScript();
            //}, 5000);
        },
        initializaPagoPluxScript() {
            const paymentDiv = document.getElementById('pagoPluxScript');
            
            try {
                if (paymentDiv) {
                    console.log("PagoPlux script already loaded!");
                }
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.onload = () => {
                   const script = document.createElement("script");
                    script.type = "text/javascript";

                    script.innerHTML = `
                        $(window).trigger('load');
                        $(document).trigger('ready');
                    `;
                    document.head.appendChild(script);
                };
                script.src = process.env.VUE_APP_PAGOPLUX_JS_URL ?? "https://sandbox-paybox.pagoplux.com/paybox/index.js";
                document.head.appendChild(script);
                
            } catch (error) {
                console.error("Error initializing PagoPlux script: ", error);
            }            
        },
        initializeOnAuthorizeCallback(){
            // MOUNTING ONAUTHORIZE METHOD 
            const backendURL = process.env.VUE_APP_API ?? "http://localhost:4000";

            this.appendScript(null, `
                var onAuthorize = function(response){
                    // La variable response posee un Objeto con la respuesta de PagoPlux.{
                    console.log(response);
                    if (!response || !response.status)
                    {
                        alert('La pasarela no pudo procesar el pago');
                        window.location.href = '/';
                    }

                    if (response.status && !response.detail) {
                        console.log("ESPERANDO OTP");
                        return;
                    }

                    // VERIFICAR EL STATUS DEL REQUEST A PAGOPLUX Y LUEGO VERIFICAR EL STATUS DEL PAGO
                    if (response.status == 'succeeded' && (response.detail))
                    {
                        if (response.detail.state != 'PAGADO')
                        {
                            alert('Pago Fallido. Por favor Intentelo nuevamente');
                            window.location.href = '/';
                            return;
                        }
                        
                        $.ajax({
                            url: '${backendURL}/pagos/procesar',
                            type: 'POST',
                            contentType: 'application/json',
                            headers: {
                                "Authorization": "Bearer ${localStorage.getItem('jwt_token_kr')}"
                            },
                            data: JSON.stringify({
                                transactionId: response.detail.id_transaccion,
                                status: response.detail.state == 'PAGADO' ? "1" : "4",
                                statusDetail: response.detail.state == 'PAGADO' ? "3" : "10",
                                fechaPago: response.detail.fecha,
                                tokenCode: response.detail.token,
                                pedidoId: ${this.reference},
                            }),
                            success: function(data){
                                window.location.href = '/pago-exitoso';
                            },
                            error: function(){
                                window.location.href = '/pago-fallido';
                            }
                        });
                    }
                };
            `);
        },
        appendScript(attribute: string | null, value: string, isAsync = false) {
            const script = document.createElement("script");
            script.type = "text/javascript";

            script.onload = () => {
                console.log(`Script loaded: ${value} - ${attribute}`);
            };
            
            if (isAsync) {
                script.async = true;
            }

            if (!attribute) {
                script.innerHTML = value;
            } else {
                script.setAttribute(attribute, value);
            }
            
            document.head.appendChild(script);            
        }
    },
    created() {
        console.log("Creating PagoPlux Payment Button");
        const paymentData = this.buildPagoPluxPayload(this.amount, this.username, this.userFullName,"Guayaquil",this.telefono, this.pagopluxButtonId, this.reference );
        this.appendScript(null, `var data = ${JSON.stringify(paymentData)};`); 

        this.initializeOnAuthorizeCallback();
    },
    mounted() {
        console.log("Mounting PagoPlux Payment Button");
        // MOUNTING VARIABLES
        
        this.initializaPagoPluxScript();        
    }
});
